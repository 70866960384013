<template>
  <div
    class=" comp_exp_llamada conflex pt-3"
     style="justify-content:center;background-color: #eee"
    if="schema.ctrls.id.value !== ''"
  >
    <!-- envolver las tres columnas de la izaquierda en 790px -->
    <!-- <div class="row-principal" style="width:790px;height:690px"> -->

    <div class="columna">
      <div class="cab">ASEGURADO TIPO</div>
      <v-sheet width="270px" height="203px" v-bind="$cfg.styles.marco">
        <v-radio-group v-model="schema.ctrls.servtipo.value" row class="mt-0">
          <div v-for="serv in itemsServTipo" :key="serv.id">
            <v-radio
              :label="serv.label"
              :value="serv.id"
              :dense="true"
              :disabled="!is_edit">
            </v-radio>

            <template v-if="serv.id === '1' && schema.ctrls.servtipo.value === '1'">            
              <div style="display:flex">
                <v-checkbox
                  v-bind="$checkbox"
                  v-model="schema.ctrls.servasegdec.value"
                  label="decesos"                  
                  :disabled="!is_edit"
                  dense>
                </v-checkbox>

                <v-checkbox style="margin-left:15px"
                  v-bind="$checkbox"
                  v-model="schema.ctrls.servasegasi.value"
                  label="asistencia"
                  :disabled="!is_edit"
                  dense>
                </v-checkbox>
              </div>
            </template>
          </div>
        </v-radio-group>
      </v-sheet>

      <div class="cab">TARIFA TRASLADO</div>
      <v-sheet width="270px" height="203px" v-bind="$cfg.styles.marco">
        <v-radio-group v-model="schema.ctrls.servtarifa.value" row :disabled="!is_edit">
          <v-radio
            v-for="serv in itemsServTarifa"
            :key="serv.id"
            :label="serv.label"
            :value="serv.id">
          </v-radio>
        </v-radio-group>
      </v-sheet>

      <div class="cab">FACTURABLE</div>
      <v-sheet width="270px"  v-bind="$cfg.styles.marco">
        <v-row>
          <v-col>
            <v-checkbox
              v-bind="$checkbox"
              v-model="schema.ctrls.solotram.value"
              :label="schema.ctrls.solotram.label"
              :disabled="!is_edit"
              dense
            ></v-checkbox>
            <v-checkbox
              v-bind="$checkbox"
              v-model="schema.ctrls.soloapd.value"
              :label="schema.ctrls.soloapd.label"
              :disabled="!is_edit"
              dense
            ></v-checkbox>
            <v-checkbox
              v-bind="$checkbox"
              v-model="schema.ctrls.varios.value"
              :label="schema.ctrls.varios.label"
              :disabled="!is_edit"
              dense
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-sheet>
    </div>

    <div class="columna">
      <div class="cab">CAPITALES</div>

      <v-sheet
        width="250px"
        
        class="pr-10"
        v-bind="$cfg.styles.marco"
      >
        <v-input_n
          v-bind="$input"
          v-model="schema.ctrls.servpm.value"
          :label="schema.ctrls.servpm.label"
          :disabled="!is_edit"
        ></v-input_n>

        <v-input_n
          v-bind="$input"
          v-model="schema.ctrls.servtana.value"
          :label="schema.ctrls.servtana.label"
          :disabled="!is_edit"
        ></v-input_n>

        <v-input_n
          v-bind="$input"
          v-model="schema.ctrls.servnicho.value"
          :label="schema.ctrls.servnicho.label"
          :disabled="!is_edit"
        ></v-input_n>

        <v-input_n
          v-bind="$input"
          v-model="schema.ctrls.servincinera.value"
          :label="schema.ctrls.servincinera.label"
          :disabled="!is_edit"
        ></v-input_n>

        <v-input_n
          v-bind="$input"
          v-model="schema.ctrls.servlapida.value"
          :label="schema.ctrls.servlapida.label"
          :disabled="!is_edit"
        ></v-input_n>

        <v-input_n
          v-bind="$input"
          v-model="schema.ctrls.servcomplemento.value"
          :label="schema.ctrls.servcomplemento.label"
          :disabled="!is_edit"
        ></v-input_n>

        <v-input_n
          v-bind="$input"
          v-model="schema.ctrls.servgasto.value"
          :label="schema.ctrls.servgasto.label"
          :disabled="!is_edit"
        ></v-input_n>

        <v-checkbox
          v-bind="$checkbox"
          v-model="schema.ctrls.servtramita.value"
          :label="schema.ctrls.servtramita.label"
          :disabled="!is_edit"
          dense
        ></v-checkbox>
      </v-sheet>

      <div class="cab">OTROS</div>
      <v-sheet  v-bind="$cfg.styles.marco">
        <v-checkbox
          v-bind="$checkbox"
          v-model="schema.ctrls.exhumacion.value"
          :label="schema.ctrls.exhumacion.label"
          :disabled="!is_edit"
          dense
        ></v-checkbox>

        <v-checkbox
          v-bind="$checkbox"
          v-model="schema.ctrls.reinhumacion.value"
          :label="schema.ctrls.reinhumacion.label"
          :disabled="!is_edit"
          dense
        ></v-checkbox>

        <v-checkbox
          v-bind="$checkbox"
          v-model="schema.ctrls.coronas.value"
          :label="schema.ctrls.coronas.label"
          :disabled="!is_edit"
          dense
        ></v-checkbox>

        <v-checkbox
          v-bind="$checkbox"
          v-model="schema.ctrls.lapidas.value"
          :label="schema.ctrls.lapidas.label"
          :disabled="!is_edit"
          dense
        ></v-checkbox>

        <v-checkbox
          v-bind="$checkbox"
          v-model="schema.ctrls.musica.value"
          :label="schema.ctrls.musica.label"
          :disabled="!is_edit"
          dense
        ></v-checkbox>

        <v-checkbox
          v-bind="$checkbox"
          v-model="schema.ctrls.esquelas.value"
          :label="schema.ctrls.esquelas.label"
          :disabled="!is_edit"
           dense
        ></v-checkbox>

        <v-checkbox
          v-bind="$checkbox"
          v-model="schema.ctrls.esparcimiento.value"
          :label="schema.ctrls.esparcimiento.label"
          :disabled="!is_edit"
           dense
        ></v-checkbox>

        <v-checkbox
          v-bind="$checkbox"
          v-model="schema.ctrls.condolencia.value"
          :label="schema.ctrls.condolencia.label"
          :disabled="!is_edit"
           dense
        ></v-checkbox>
      </v-sheet>
    </div>

    <div class="columna">
      <div class="cab"> G P SERVICIO</div>
      <v-sheet width="260px"  v-bind="$cfg.styles.marco">
        <div style="display:flex">
          <v-checkbox            
            v-bind="$checkbox"
            v-model="schema.ctrls.inhumacion.value"
            :label="schema.ctrls.inhumacion.label"
            :disabled="!is_edit"
             dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.p_inhumacion.value"
            :label="schema.ctrls.p_inhumacion.label"
            :disabled="!is_edit || schema.ctrls.p_incineracion.value=='1'"
             dense>
          </v-checkbox>
        </div>
        <div style="display:flex">
          <v-checkbox            
            v-bind="$checkbox"
            v-model="schema.ctrls.incineracion.value"
            :label="schema.ctrls.incineracion.label"
            :disabled="!is_edit"
             dense>
          </v-checkbox>      

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.p_incineracion.value"
            :label="schema.ctrls.p_incineracion.label"
            :disabled="!is_edit || schema.ctrls.p_inhumacion.value=='1'"
             dense
          ></v-checkbox>
        </div>
        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.recepcion.value"
            :label="schema.ctrls.recepcion.label"
            :disabled="!is_edit"
             dense
          ></v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.p_recepcion.value"
            :label="schema.ctrls.p_recepcion.label"
            :disabled="!is_edit"
             dense
          ></v-checkbox>
        </div>
        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.terminacion.value"
            :label="schema.ctrls.terminacion.label"
            :disabled="!is_edit"
             dense
          ></v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.p_terminacion.value"
            :label="schema.ctrls.p_terminacion.label"
            :disabled="!is_edit"
             dense
          ></v-checkbox>
        </div>

        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.crionizacion.value"
            :label="schema.ctrls.crionizacion.label"
            :disabled="!is_edit"
             dense
          ></v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.p_crionizacion.value"
            :label="schema.ctrls.p_crionizacion.label"
            :disabled="!is_edit"
             dense
          ></v-checkbox>
        </div>
      </v-sheet>

      <div class="cab">AMBITO</div>
      <v-sheet  v-bind="$cfg.styles.marco">
        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.local.value"
            :label="schema.ctrls.local.label"
            :disabled="!is_edit"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.p_local.value"
            :label="schema.ctrls.p_local.label"
            :disabled="!is_edit"
            dense
          ></v-checkbox>
        </div>
        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.provincial.value"
            :label="schema.ctrls.provincial.label"
            :disabled="!is_edit"
            dense
          ></v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.p_provincial.value"
            :label="schema.ctrls.p_provincial.label"
            :disabled="!is_edit"
            dense
          ></v-checkbox>
        </div>
        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.nacional.value"
            :label="schema.ctrls.nacional.label"
            :disabled="!is_edit"
            dense
          ></v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.p_nacional.value"
            :label="schema.ctrls.p_nacional.label"
            :disabled="!is_edit"
            dense
          ></v-checkbox>
        </div>
        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.internacional.value"
            :label="schema.ctrls.internacional.label"
            :disabled="!is_edit"
            dense
          ></v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.p_internacional.value"
            :label="schema.ctrls.p_internacional.label"
            :disabled="!is_edit"
            dense
          ></v-checkbox>
        </div>
      </v-sheet>

      <div class="cab">OTROS SERVICIOS</div>
      <v-sheet v-bind="$cfg.styles.marco">
        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.apd.value"
            :label="schema.ctrls.apd.label"
            :disabled="!is_edit"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.p_apd.value"
            :label="schema.ctrls.p_apd.label"
            :disabled="!is_edit"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.apdauto.value"
            :label="schema.ctrls.apdauto.label"           
            :disabled="is_edit && $store.state.G.USUDAT.ndep==9? false:true"
            dense>
          </v-checkbox>   
        </div>

        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.criopre.value"
            :label="schema.ctrls.criopre.label"
            :disabled="!is_edit"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.p_criopre.value"
            :label="schema.ctrls.p_criopre.label"          
            :disabled="!is_edit"
            dense>
          </v-checkbox>
        </div>
        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.fetos.value"
            :disabled="!is_edit"
            dense
          ></v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.p_fetos.value"
            :label="schema.ctrls.p_fetos.label"
            :disabled="!is_edit"
            desnse
          ></v-checkbox>
        </div>
        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.neonato.value"
            :disabled="!is_edit"
            dense
          ></v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.p_neonato.value"
            :label="schema.ctrls.p_neonato.label"
            :disabled="!is_edit"
            dense
          ></v-checkbox>
        </div>
        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.miembros.value"
            :label="schema.ctrls.miembros.label"
            :disabled="!is_edit"
            dense
          ></v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.p_miembros.value"
            :label="schema.ctrls.p_miembros.label"
            :disabled="!is_edit"
            dense
          ></v-checkbox>
        </div>
        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.donacion.value"
            :label="schema.ctrls.donacion.label"
            :disabled="!is_edit"
            dense
          ></v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.p_donacion.value"
            :label="schema.ctrls.p_donacion.label"
            :disabled="!is_edit"
            dense
          ></v-checkbox>
        </div>
        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.asisp.value"
            :label="schema.ctrls.asisp.label"
            :disabled="!is_edit"
            dense
          ></v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.p_asisp.value"
            :label="schema.ctrls.p_asisp.label"
            :disabled="!is_edit"
            dense
          ></v-checkbox>
        </div>
        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.adn.value"
            :label="schema.ctrls.adn.label"
            :disabled="!is_edit"
            dense
          ></v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.p_adn.value"
            :label="schema.ctrls.p_adn.label"
            :disabled="!is_edit"
            dense
          ></v-checkbox>
        </div>
      </v-sheet>
    </div>

    <!-- </div> -->
    <!-- </v-sheet> -->

    <div class="columna">
      <div class="cab fullrow">FUNERARIAS QUE INTERVIENEN</div>
      <v-sheet height="600px" width="756px" v-bind="$cfg.styles.marco">
        <div class="grid">
          <v-checkbox            
            v-bind="$checkbox"
            v-model="schema.ctrls.preferencia.value"
            label="Prefcia"
            :disabled="!is_edit"
            dense>
          </v-checkbox>

          <v-select
            v-bind="$select"
            v-model="schema.ctrls.motivo_pref.value"
            :items="ST_motivo"
            :disabled="!is_edit"
            item-value="id"
            item-text="name">
          </v-select>

          <v-checkbox            
            v-bind="$checkbox"
            v-model="schema.ctrls.vip.value"
            :label="schema.ctrls.vip.label"
            :disabled="!is_edit"
            dense>
          </v-checkbox>

          <div class="conflex" style="grid-column:span 2">
            <ctrlfinder
              style="width:90%"
              finderName="funes_F"
              :schema="schema.ctrls.fune1"
              :edicion="is_edit">
            </ctrlfinder>

            <!-- View de Funerarias Sucursales -->
            <div>
              <funes_M_view                
                :padre="padre"
                accion="0"
                :accionRow="{ id:schema.ctrls.fune1.value }">               
              </funes_M_view>
            </div>
          </div>

          <div class="conflex" style="grid-column:span 2">
            <ctrlfinder
              style="width:80%"
              finderName="exp_Apd_F"
              :padre="padre"
              :schema="schema.ctrls.apd1"
              :fn="extraid_apd1"
              :edicion="is_edit">
            </ctrlfinder>

            <!-- View de APDS -->
            <div>
              <apd_M_view
                :padre="padre"
                accion="0"
                :auxRecord="record"
                :apd=1
                :accionRow="{ id:schema.ctrls.apd1.value }">               
              </apd_M_view>               
            </div>

            <v-btn 
              v-bind="$cfg.btra_cfg.standard"             
              title="Informe Atención Personal APD1"
              @click="view_Iap(1)">
                <v-icon dark>{{ 'mdi-account-check' }}</v-icon>
            </v-btn>
          </div>

          <div class="conflex" style="grid-column:span 2">
            <ctrlfinder
              style="width:90%"
              finderName="funes_F"
              :schema="schema.ctrls.fune2"
              :edicion="is_edit">
            </ctrlfinder>

            <!-- View de Funerarias Sucursales -->
            <div>
              <funes_M_view
                :padre="padre"
                accion="0"
                :accionRow="{ id:schema.ctrls.fune2.value }">                
              </funes_M_view>
            </div>
          </div>

          <div class="conflex" style="grid-column:span 2">
            <ctrlfinder   
              style="width:80%"         
              finderName="exp_Apd_F"
              :padre="padre"
              :schema="schema.ctrls.apd2"
              :fn="extraid_apd2"
              :edicion="is_edit">
            </ctrlfinder>

            <!-- View de APDS -->
            <div>
              <apd_M_view
                :padre="padre"
                accion="0"
                :auxRecord="record"
                :apd=2
                :accionRow="{ id:schema.ctrls.apd2.value }">
              </apd_M_view>               
            </div>

            <v-btn 
              v-bind="$cfg.btra_cfg.standard"            
              title="Informe Atención Personal APD2"
              @click="view_Iap(2)">
                <v-icon dark>{{ 'mdi-account-check' }}</v-icon>
            </v-btn>
          </div>

          <div style="grid-column:span 2"></div>

          <ctrlfinder
            finderName="jefeszona_F"
            :schema="schema.ctrls.zona_apoyo"
            :edicion="is_edit">
          </ctrlfinder>

          <div style="grid-column:span 4">
            <ctrlobs3
            :schemaComponente="schema.ctrls.servobs"
            :schema="schema" 
            :record="record"           
            :edicion="is_edit">
          </ctrlobs3>           
          </div>
        </div>
      </v-sheet>
    </div>
	
	<!-- Componentes dinámicos -->      
      <v-dialog
        v-model="modal"
        content-class="modal"
        persistent>
          <!-- <div class="centrado" v-if="selected.length"> -->
          <div class="centrado">
            <component                 
              :is="componente_dinamico"
              :padre="stName"
              accion="0"              
              :record="record"
              :apd="apd_iap"              
              componenteTipo="FC"
              :disparo="disparo"
              @onEvent="$event.accion=='cerrar' || $event.accion==6? cerrar_dinamico() : ''">
            </component>           
          </div>
      </v-dialog>
  </div>
  <!-- </div> -->
</template>


<script>
  import plugs from "@/common/general_plugs";
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");  
  const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");
  const funes_M_view = () => plugs.groute("funes_M_view.vue", "comp");
  const apd_M_view = () => plugs.groute("apd_M_view.vue", "comp");
  const apds_iap = () => plugs.groute("apds_iap.vue", "comp");

  export default {
    components: { ctrlfinder, ctrlobs3, funes_M_view, apd_M_view, apds_iap },
    props: {
      padre: { type:String, default: '' },      
      componenteTipo: { type:String, default:'M' },      
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema:null,
        ST_motivo:[],
        itemsServTipo:[],
        itemsServTarifa:[],
		
		stName:'stMexp_M_servicio',


        //modal
        //record:{},
        apd_iap: 0,
        componente_dinamico:null,
        modal:false,
        disparo:false,
      };
    },
    
    created(){
      this.ini_data();
    },

    methods:{
      ini_data() {
        console.log(" dev ********************** exp_M_servicio ini_data");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.padre].schema;  

        this.ST_motivo = this.$store.state.datos_iniciales.motivos_prefam;
        this.ST_motivo.unshift({ id: "0", name: "Seleccione Motivo" });
        this.itemsServTipo=[
            { id: "1", label: "Asegurado" },
            { id: "2", label: "Concertado" },
            { id: "3", label: "Particular" }
          ];
          this.itemsServTarifa=[
            { id: "1", label: "A" },
            { id: "2", label: "B" },
            { id: "3", label: "C" },
            { id: "4", label: "Mínimo" },
            { id: "5", label: "Internacional" },
            { id: "6", label: "Otros Paises" },
            { id: "7", label: "Funes-Cias" }
          ]
      },


      // muestro Informe Atención Personal APD 1
      async view_Iap(apd) {
        
        var ctrls= this.schema.ctrls;

        if (ctrls['apd' + apd].value== "" || ctrls['apd' + apd].value== "0") return;        //this.iap(apd);

        if (ctrls['iap' + apd].value!= 0) {
          var args = { tipo: "fnc", accion:'documentos', fn_args: { accion:'fichero', tp: 1, id: ctrls['iap' + apd].value }};
          console.log('args: ', args);      
          var apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
          if (apiResult.sql.error) {
            this.$root.$alert.open(apiResult.sql.msg, 'error');
            return;
          }

          window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");
          return;
        }

        // compruebo datos mínimos
        var msg= "";      
        if (ctrls.fallename.value=='' || ctrls.falledni.value=='') msg+='* Faltan Datos de Fallecido<br>';
        if (ctrls.poliza.value=='') msg+='* Falta Número de Poliza<br>';
        if (ctrls.callref1.value=='') msg+='* Falta Referencia Compañia<br>';
        if (ctrls.p_inhumacion.value==0 && ctrls.p_incineracion.value==0 && ctrls.p_donacion.value==0) msg+='* Debe estar seleccionado al menos Inhumación / Incineración ó Donación<br>';
        if (ctrls.p_local.value==0 && ctrls.p_provincial.value==0 && ctrls.p_nacional.value==0 && ctrls.p_internacional.value==0) msg+='* Traslado: Debe estar seleccionado al menos uno ...<br>';
        if (ctrls.soliname.value=='' || ctrls.solidni.value=='' || ctrls.soliparen.value=='' || ctrls.solitlf1.value=='') msg+='* Faltan datos del Solicitante<br>';
        if (ctrls.fune1.value==0 && ctrls.fune2.value==0) msg+='* Falta al menos una Funeraria<br>';
        if (msg!="") {
          msg= "<b>DATOS INCOMPLETOS</b><br>" + msg;
          this.$root.$alert.open(msg, 'error');
          return;
        }

        // compruebo si está el Mto en edición
        if (this.is_edit) { 
          this.$root.$alert.open('Antes de firmar debe guardar los cambios realizados', 'info');
          return;
        }

        // relleno formulario IAP
        window.open(this.$store.state.G.url_iap + "?w=1245678789|" + ctrls.id.value + "|" + apd, "_blank");
        //this.iap(apd);

    },
    
    // ver iap para firma
      iap(apd) {

        this.componente_dinamico= "apds_iap";
        this.apd_iap=apd;
        this.disparo= !this.disparo;
        this.modal=true;

      },

      cerrar_dinamico() {        
        this.modal= false;
        this.componente_dinamico= null;
      },

      cerrar() {
        //this.$emit('aviso', {accion:null});
        this.cerrar_dinamico();
      },

      extraid_apd1(r){                
        this.schema.ctrls.zona_apd1.value= r.zona;
      },
      
      extraid_apd2(r){                
        this.schema.ctrls.zona_apd2.value= r.zona;
      },
     
    },


    computed: {
      record: function() { return this.$store.state[this.padre].record; },

      // devuelvo si estamos en modo edición en el Mto
      is_edit() { 
        return this.$store.state[this.padre].estado === "editar" || this.$store.state[this.padre].estado === "nuevo"? true : false;
      },
    }
  };
</script>


<style>
.row-principal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* margin: 0 auto; */
  align-items: center;
}

.sub-row {
  flex: 1 1 1fr;
  /* display: row; */
  /* justify-content: center;
      align-content: stretch;
      */
}

.columna {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  /* justify-content: center; */

  /* flex-wrap:wrap; */
  /* align-items: stretch; */
  /* margin-left: 3px;*/
  /* margin-right: 3px;  */
  /* border: 0; */
}

.caja {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  padding-left: 3px;
  padding-right: 3px;
  border: 0;
  background-color: #f5f5f5;

  border-radius: 0 0 5px 5px;
}

.v-text-field--filled > .v-input__control > .v-input__slot {
  background: rgba(82, 16, 16);
}
/* .v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot.v-{
      background-color:white;
    } */
.grid {
  flex: 0 0 auto;
  display: grid;
  grid-template-columns: 80px 1fr 1fr 50px;
  grid-template-rows: 3rem repeat(4, 3rem);
  grid-gap: 3px;
  grid-column-gap: 3px;

  /* background-color: #f5f8f6; */
  border: 0;
  border-radius: 0 0 5px 5px;
}

.fullrow {
  grid-column: 1/-1;
}
.enlinea {
  display: inline-block;
}
.form-field__control {
  background: rgb(194, 10, 10);
}
/* input[type="text"]:disabled{background-color:red;}
    .theme--light.v-input:not(.v-input--is-disabled) input{
      background-color:rgb(30, 170, 49);
    } */
/* 
    @media (max-width: 600px) {
      .contenedor {
        height: auto;
      }
    } */
</style>
